export enum FeatureTypes {
	FOOTBALL = 'football',
	FOOTBALL_TRANSLATION = 'football_entity_translation',
	VIDEO = 'video',
	NEWS_FEED = 'news_feed',
	AUTO_TAGGING = 'automated_tagging',
	FOOTBALL_CONNECTIONS = 'football_connections',
	WIDGET_ODDS = 'widget_odds',
	WIDGET_TEAM_PROFILE = 'widget_team_profile',
	WIDGET_SQUAD = 'widget_squad',
	WIDGET_PLAYER_PROFILE = 'widget_player_profile',
	WIDGET_MATCH = 'widget_match',
	WIDGET_FIXTURES = 'widget_fixtures',
	WIDGET_TOP_SCORERS = 'widget_top_scorers',
	WIDGET_STANDINGS = 'widget_standings',
	WIDGET_PLAYER_H2H = 'widget_player_h2h',
	WIDGET_TEAM_H2H = 'widget_team_h2h',
	WIDGET_MATCH_LIST_STAGE_ROUND = 'widget_match_list_stage_round',
	WIDGET_TEAM_FORM = 'widget_team_form',
	WIDGET_PLAYER_CAREER = 'widget_player_career',
	WIDGET_SQUAD_STATISTICS = 'widget_squad_statistics',
	GENERIC_FIELD = 'generic_field',
	ALLOW_BOOKMAKER_SELECTION = 'allow_bookmaker_selection',
	WIDGET_EMBED = 'widget_embed',
	DUGOUT_INTEGRATION = 'dugout_integration',
	ON_NETWORK_INTEGRATION = 'onnetwork_integration',
	PLAYING_SURFACE_INTEGRATION = 'playing_surface_integration',
	MANUAL_DATA_ENTRY_TEAM = 'manual_data_entry_team',
	MANUAL_DATA_ENTRY_PLAYER = 'manual_data_entry_player',
	MANUAL_DATA_ENTRY_COACH = 'manual_data_entry_coach',
	MANUAL_DATA_ENTRY_TOURNAMENT_GROUPS = 'manual_data_entry_tournament_groups',
	MANUAL_DATA_ENTRY_TOURNAMENT = 'manual_data_entry_tournament',
	WIDGET_MOST_DECORATED_PLAYERS = 'widget_most_decorated_players',
	MATCH_LINEUPS = 'manual_data_entry_match',
	SPOTLIGHT_AUDIO_INTEGRATION = 'spotlight_audio_integration',
	GB_VISION_INTEGRATION = 'gb_vision_audio_integration',
	VIDEO_URLS = 'video_urls',
	CONTENT_PREVIEW = 'content_preview',
	QUICK_ACCESS = 'quick_access',
	CONTENT_FOOTER = 'content_footer',
	IMAGO_INTEGRATION = 'imago_integration',
	CONTENT_STATISTICS = 'content_statistics',
	LIMIT_FOOTBALL_MANUAL_DATA = 'limit_football_manual_data',
	INSTAT_INTEGRATION = 'instat_integration',
	YOUTUBE_SPORTS = 'youtube_sports_integration',
	MANDATORY_FIELDS = 'mandatory_fields',
	DISPLAY_INACTIVE_CATEGORIES = 'display_inactive_categories',
	WORDCOUNT = 'wordcount',
	WATERMARK = 'watermark',
	CONTENT_ANALYTICS = 'content_analytics',
	CUSTOM_BLOCKS = 'custom_blocks',
	AUDIT_LOG = 'audit_log',
	AFFILIATES = 'affiliates',
	BASKETBALL = 'basketball',
	BASKETBALL_SINGLE_EVENT = 'basketball_single_event',
	BASKETBALL_LIVESCORE = 'basketball_livescore',
	BASKETBALL_STANDINGS = 'basketball_standings',
	BASKETBALL_TEAM_PROGRAMME_BLOCK = 'basketball_team_programme',
	BASKETBALL_TOURNAMENT_PROGRAMME_BLOCK = 'basketball_tournament_programme',
	ONESIGNAL_INTEGRATION = 'onesignal_integration',
	CONTENT_BLOCKY_UNDO_REDO = 'content_undo_redo',
	FANS_UNITED = 'fans_united',
	WIKI_PAGES = 'wiki_pages',
	TENNIS = 'tennis',
	TENNIS_SINGLE_EVENT = 'tennis_single_event',
	TENNIS_LIVESCORE = 'tennis_livescore',
	TENNIS_RANKING = 'tennis_ranking',
	TENNIS_PLAYOFF = 'tennis_playoff',
	TENNIS_ATHLETE_PROGRAMME_BLOCK = 'tennis_athlete_programme',
	TENNIS_TOURNAMENT_PROGRAMME_BLOCK = 'tennis_tournament_programme',
	AMG_INTEGRATION = 'stream_amg',
	FOOTBALL_V2 = 'football_v2',
	FOOTBALL_PLAYER_H2H = 'football_player_h2h',
	FOOTBALL_TEAM_H2H = 'football_team_h2h',
	FOOTBALL_TEAM_H2H_MATCH = 'football_team_h2h_match',
	FOOTBALL_MATCHES_H2H = 'football_matches_h2h',
	FOOTBALL_TOURNAMENT_PROGRAMME = 'football_tournament_programme',
	FOOTBALL_TEAM_PROGRAMME = 'football_team_programme',
	FOOTBALL_TEAM_SQUAD = 'football_team_squad',
	FOOTBALL_PLAYER = 'football_player',
	FOOTBALL_TEAM = 'football_team',
	FOOTBALL_SINGLE_EVENT = 'football_single_event',
	FOOTBALL_LIVESCORE = 'football_livescore',
	FOOTBALL_STANDINGS = 'football_standings',
	FOOTBALL_KNOCKOUT = 'football_knockout',
	FOOTBALL_TEAM_FORM = 'football_team_form',
	FOOTBALL_TOP_SCORERS = 'football_top_scorers',
	FOOTBALL_MOST_DECORATED_PLAYERS = 'football_most_decorated_players',
	FOOTBALL_LINEUPS = 'football_lineups',
	LIVE_BLOG = 'live_blog',
	JW_VIDEO = 'jw_video',
	ARTICLE_LOCK = 'article_lock',
	SCHEDULED_LISTS = 'scheduled_lists',
	WIDGET_ODDS_V2 = 'football_odds',
	GETTY_IMAGES = 'getty_images',
	MAIN_MEDIA_ADDITIONAL_IMAGES = 'main_media_additional_images',
	MULTISPORT_WIDGET_CONFIGURATION = 'multisport_widget_configuration',
	MAIN_MEDIA_IMAGE_PREFILL = 'integrations_media_description_prefill',
	CUSTOM_ENTITIES = 'custom_entities',
	WIDGET_STAKES = 'football_stakes',
	WIDGET_V2_CUSTOM_ATTRIBUTES = 'widget_v2_custom_attributes',
	RICH_TEXT_BIO = 'rich_text_bio',
	AUTO_CHECK_DISPLAY_ODDS = 'auto_check_display_odds',
	ICE_HOCKEY = 'ice_hockey',
	ICE_HOCKEY_LIVESCORE = 'ice_hockey_livescore',
	ICE_HOCKEY_SINGLE_EVENT = 'ice_hockey_single_event',
}
